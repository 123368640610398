/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: Welkin
 * @LastEditTime: 2022-08-01 15:33:17
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import Layout from '@/layout/Index.vue'

const constantFiles = require.context('./constantModules', true, /\.ts$/)
let constantModules: Array<RouteRecordRaw> = []
constantFiles.keys().forEach((key) => {
  if (key === './index.ts') return
  constantModules = constantModules.concat(constantFiles(key).default)
})

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    // component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue')
      }
    ]
  },
  {
    path: '/clipboard',
    name: 'clipboard',
    meta: {
      name: '首页',
      nacy: 'true' // 是否不做token验证(是否不做登录校验)
    },
    component: () => import(/* webpackChunkName: "joboverview" */ '@/views/clipboard/Index.vue')
  },
  {
    path: '/',
    redirect: '/clipboard'
  },

  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/clipboard',
  //   children: [
  //     {
  //       path: 'clipboard',
  //       component: () => import(/* webpackChunkName: "dashboard" */ '@/views/clipboard/Index.vue'),
  //       name: 'clipboard',
  //       meta: {
  //         title: 'clipboard',
  //         icon: '#icondashboard',
  //         affix: true
  //       }
  //     }
  //   ]
  // },
  ...constantModules
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
