import https from '@/utils/https'
/*
 * @Description: 用户相关接口
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: Welkin
 * @LastEditTime: 2022-09-29 16:22:47
 */
import { RootObject, RootHasEErrorObject, RootObjectNew } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'

import { LoginModel, Captcha, DeleateModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'

// 获取图形验证码
export const getCaptcha = () => {
  return https.request<RootObject<Captcha>>('/rbac/access/getCaptcha', Method.GET, undefined, ContentType.json)
}

// 登录接口-无验证码
// export const accountLogin = (userInfo: RequestParams) => {
//   return https.request<RootHasEErrorObject<LoginModel>>('/SysUserAcc/doLogin', Method.POST, userInfo, ContentType.json)
// }

// 登录接口-无验证码
export const accountLogin = (userInfo: RequestParams) => {
  return https.request<RootHasEErrorObject<LoginModel>>('/phoenix/api/sysuser/login', Method.POST, userInfo, ContentType.json)
}

export const loginRequest = (userInfo: RequestParams) => {
  return https.request<RootObject<LoginModel>>('user/login', Method.POST, userInfo, ContentType.json)
}

export const userInfoRequest = () => {
  return https.request<RootObject<UserInfoModel>>('user/userInfo', Method.GET, undefined, ContentType.form)
}

export const getUsers = (user: any) => {
  return https.request<RootObject<Users>>('user/getUsers', Method.GET, user, ContentType.form)
}

// 测试单点登录接口
export const noLogina = () => {
  return https.request<RootObject<Captcha>>('/mwchannel/auth/atlasSession', Method.GET, undefined, ContentType.json)
}
// 测试单点登录接口
export const noLoginb = () => {
  return https.request<RootObject<Captcha>>('/mwchannel/auth/dsSession', Method.GET, undefined, ContentType.json)
}

// 大屏跳转-生产接口
export const noLoginabaPro = (userInfo: RequestParams) => {
  return https.request<RootHasEErrorObject<LoginModel>>('/nbsso/sso/production', Method.POST, userInfo, ContentType.json)
}
// 大屏跳转-销售接口
export const noLoginabaSal = (userInfo: RequestParams) => {
  return https.request<RootHasEErrorObject<LoginModel>>('/nbsso/sso/sale', Method.POST, userInfo, ContentType.json)
}
