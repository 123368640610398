/*
 * @Description: 网络配置常量
 * @Author: ZY
 * @Date: 2020-12-08 13:34:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-08 13:39:56
 */

export enum InfoShowType{
     LOG,
     NOTIFICATION,
     TOAST
 }
