/*
 * @Description: app actions type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-11 11:18:02
 */

export enum TagsActionTypes {
  ACTION_ADD_VIEW = 'ACTION_ADD_VIEW',
  ACTION_ADD_VISITED_VIEW = 'ACTION_ADD_VISITED_VIEW',
  ACTION_DEL_VIEW = 'ACTION_DEL_VIEW',
  ACTION_DEL_OTHER_VIEW = 'ACTION_DEL_OTHER_VIEW',
  ACTION_DEL_CACHED_VIEW = 'ACTION_DEL_CACHED_VIEW',
  ACTION_OTHER_VIEWS = 'ACTION_OTHER_VIEWS',
  ACTION_DEL_ALL_VIEWS = 'ACTION_DEL_ALL_VIEWS',
  ACTION_DEL_ALL_CACHED_VIEWS = 'ACTION_DEL_ALL_CACHED_VIEWS',
  ACTION_UPDATE_VISITED_VIEW = 'ACTION_UPDATE_VISITED_VIEW',
}
