/*
 * @Description: axios 封装网络请求
 * @Author: ZY
 * @Date: 2020-12-28 14:45:32
 * @LastEditors: Welkin
 * @LastEditTime: 2022-09-15 16:40:21
 */

import HttpClient, { HttpClientConfig } from 'axios-mapper'
import networkConfig from '@/config/default/net.config'
import { getToken, getUserInfo } from '@/utils/cookies'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { ElNotification } from 'element-plus'
// const https = (hasToken: Boolean = true) => {
//   const config: HttpClientConfig = {
//     baseURL: networkConfig.host,
//     headers: {
//       token: hasToken ? useStore().state.user.token : ''
//     }
//   }
//   return new HttpClient(config)
// }

const config: HttpClientConfig = {
  baseURL: networkConfig.host,
  headers: {
    token: ''
  }
}

const https = new HttpClient(config)

// 将Object的属性输出成Array
function objOfPropertyToArr(urlObject: any) {
  const arr = []
  for (const i in urlObject) {
    arr.push(urlObject[i])
  }
  return arr
}

// Add a request interceptor
https.httpClient.interceptors.request.use(function(config) {
  // Do something before request is sent

  const token = getToken()
  // const organizationId = useStore().state.user.organizationId
  // const id = useStore().state.user.id
  // const name = useStore().state.user.name
  // const username = useStore().state.user.username

  const userInfo: any = getUserInfo()
  // console.log('userInfo:', userInfo)
  let username = ''
  let name = ''
  let id = 0
  let organizationId = 0
  if (userInfo) {
    username = JSON.parse(userInfo).username
    name = JSON.parse(userInfo).name
    id = JSON.parse(userInfo).id
    organizationId = JSON.parse(userInfo).organizationId
  }

  const bodyData = JSON.parse(config.data)
  // console.log('请求拦截', bodyData)
  const urlObject = config.url?.split('/')
  const urlList = objOfPropertyToArr(urlObject)

  if (token) {
    config.headers.token = token
    // config.headers.token = '11111122222'
  }
  if (config.method === 'post') {
    if (['supersetFactory2', 'api'].includes(urlList[1])) {
      config.data = {
        ...bodyData.body
      }
    } else if (config.url === '/nbsso/sso/production') {
      config.data = {

      }
    } else if (config.url === '/SysUserAcc/doLogin') {
      config.data = {
        ...bodyData.body
      }
    } else if (config.url === '/dragon/api/dataassetlist/batchinsert' || config.url === '/dragon/api/dataassetusersubscribe/batchadd') { // 批量添加资产 || 批量添加用户
      config.data = {
        body: bodyData.body,
        header: {
          fromSystem: 'OA',
          language: 'zh-cn',
          operationUser: {
            organizationId: organizationId || 1,
            operation: 'Update',
            displayName: name, // name
            loginName: username, // username
            sourceDirectoryKey: '1',
            companyCode: '0',
            userSysNo: id // id
          },
          sender: 'unicom',
          ...JSON.parse(config.data).header
        }
      }
    } else {
      config.data = {
        body: {
          organizationId: organizationId,
          projectId: 0,
          ...bodyData.body
        },
        header: {
          fromSystem: 'OA',
          language: 'zh-cn',
          operationUser: {
            organizationId: organizationId || 1,
            operation: 'Update',
            displayName: name, // name
            loginName: username, // username
            sourceDirectoryKey: '1',
            companyCode: '0',
            userSysNo: id // id
          },
          sender: 'unicom',
          ...JSON.parse(config.data).header
        },
        pagingInfo: {
          pageIndex: null,
          pageSize: null,
          sortField: 'id',
          sortType: 'DESC',
          totalCount: null,
          ...JSON.parse(config.data).pagingInfo
        }
      }
    }
  } else if (config.method === 'get') {
    config.params = {
      organizationId: organizationId,
      ...(bodyData.params)
    }
  }
  return config
}, function(error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
https.httpClient.interceptors.response.use(function(response) {
  // Do something with response data
  // console.log('响应拦截')
  if (response.data.code && response.data.code !== 200) {
    ElNotification({
      title: 'Error',
      message: response.data.message,
      type: 'error'
    })
  } else if (response.data.hasError) {
    // console.log('响应拦截', response.data.errorCode)

    if (response.data.errorCode === '5007') {
      useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
      window.location.href = '/login'
    } else if (response.data.errorCode === '9999') {
      ElNotification({
        title: 'Error',
        message: '系统未知错误',
        type: 'error'
      })
    } else {
      ElNotification({
        title: 'Error',
        message: response.data.notifyMessage,
        type: 'error'
      })
    }
  }
  return response
}, function(error) {
  // console.log('error111111!:', error)
  // Do something with response error
  return Promise.reject(error)
})

export default https
