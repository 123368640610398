/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-08 09:50:38
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-08 11:47:10
 */

export enum ContentType{
    FORM = 'application/x-www-form-urlencoded',
    JSON = 'application/json; charset=utf-8'
}

export enum Device{
    IOS = 'iOS',
    ANDROID = 'Android',
    WINDOWS = 'Windows',
    PC='PC'
}
