<!--
 * @Description:
 * @Autor: ZY
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: Welkin
 * @LastEditTime: 2022-08-19 15:43:44
-->
<template>
  <!-- <appHead class="appHeadMain" v-show="$route.path !== '/login'" /> -->
  <!-- <div> -->
  <router-view class="appViewMain" />
  <!-- </div> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import appHead from './layout/components/appHead/Index_copy.vue'

export default defineComponent({
  components: {
    // appHead
  }
})
</script>
<style lang="scss">
.el-button--primary {
    // --el-button-text-color: var(--el-color-white);
    // --el-button-bg-color: var(--el-color-primary);
     --el-button-bg-color: rgb(100, 117, 171);
    // --el-button-border-color: var(--el-color-primary);
    // --el-button-outline-color: var(--el-color-primary-light-5);
    // --el-button-active-color: var(--el-color-primary-dark-2);
    // --el-button-hover-text-color: var(--el-color-white);
    // --el-button-hover-link-text-color: var(--el-color-primary-light-5);
    // --el-button-hover-bg-color: var(--el-color-primary-light-3);
    // --el-button-hover-border-color: var(--el-color-primary-light-3);
    // --el-button-active-bg-color: var(--el-color-primary-dark-2);
    // --el-button-active-border-color: var(--el-color-primary-dark-2);
    // --el-button-disabled-text-color: var(--el-color-white);
    // --el-button-disabled-bg-color: var(--el-color-primary-light-5);
    // --el-button-disabled-border-color: var(--el-color-primary-light-5);
}
.appViewMain {
  // margin-top: 60px;
}
.appHeadMain {
  position: fixed;
  top: 0;
  z-index: 50;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #4385F4 !important;
}

.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0 !important;
  color: #fff !important;
  background: #4385F4 !important;
  border-radius: 0px !important;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #fff !important;
  background: #4385F4 !important;
  border-radius: 0px !important;
}
.el-submenu__title i {
    color: #fff !important;
}
</style>
