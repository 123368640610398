/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-17 16:06:56
 * @LastEditors: Welkin
 * @LastEditTime: 2022-07-12 18:14:04
 */

class Keys {
  static sidebarStatusKey = 'vue3-typescript-admin-sidebarStatusKey'
  static languageKey = 'vue3-typescript-admin-languageKey'
  static sizeKey = 'vue3-typescript-admin-sizeKey'
  // static tokenKey = 'vue3-typescript-admin-access-token'
  static tokenKey = 'satoken'
  static aseKey = 'vue3-typescript-admin-ase-key'
  static user_info = 'user_info'
}

export default Keys
